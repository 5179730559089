import { handleResponse, handleError } from "./apiUtils";
import { getIdpAccessToken } from "../sagas/idpSaga";
const baseUrl = process.env.REACT_APP_API_URL;

function getRealAccessToken() {
  const accessToken = getIdpAccessToken();
  if (accessToken) return accessToken;
}

export function getSymptomByPartId(partId) {
  const internalProvidersUrl = baseUrl + "/api/v1/symptom/symptom/" + partId;
  var url = new URL(internalProvidersUrl);
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getGqualifierByPartIdAndSymptomId(partId, symptomId) {
  const internalProvidersUrl =
    baseUrl + "/api/v1/symptom/gqualifier/" + partId + "/" + symptomId;
  var url = new URL(internalProvidersUrl);
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getSqualifierByPartIdAndSymptomIdAndGqualifierId(
  partId,
  symptomId,
  gqualifierId
) {
  const internalProvidersUrl =
    baseUrl +
    "/api/v1/symptom/squalifier/" +
    partId +
    "/" +
    symptomId +
    "/" +
    gqualifierId;
  var url = new URL(internalProvidersUrl);
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getSymptoms() {
  const internalProvidersUrl = baseUrl + "/api/v1/symptom";
  var url = new URL(internalProvidersUrl);
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getGqualifiers() {
  const internalProvidersUrl = baseUrl + "/api/v1/symptom/gqualifier";
  var url = new URL(internalProvidersUrl);
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getSqualifiers() {
  const internalProvidersUrl = baseUrl + "/api/v1/symptom/squalifier";
  var url = new URL(internalProvidersUrl);
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .catch(handleError);
}
