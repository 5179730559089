import { combineReducers } from "redux";
import app from "./appReducer";
import auth from "./authReducer";
import layout from "./layoutReducer";
import user from "./userReducer";
import organization from "./organizationReducer";
import webRtc from "./webRtcReducer";

const rootReducer = combineReducers({
  app,
  auth,
  layout,
  user,
  organization,
  webRtc,
});

export default rootReducer;
